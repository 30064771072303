/* @font-face {
  font-family: "GothamBook";
  src: url("/fonts/Gotham\ Book.otf") format("opentype");
} */

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

#info b {
  color: #0067dd;
}

#info span {
  display: inline-block;
  box-shadow: 0 2px 0 0 #0067dd;
}

* {
  box-sizing: border-box;
  /* font-family: "GothamBook", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important; */
  letter-spacing: 0.5px;
  white-space: pre-wrap;
}

.slick-dots {
  bottom: 20px !important;
}

.slick-dots li button:before {
  color: #fff !important;
}

/* datetime picker style rewrite */
div.react-datepicker__header {
  background-color: #000;
}

div.react-datepicker__day-name,
div.react-datepicker__time-name {
  color: #fff;
  font-weight: 500;
}

div.react-datepicker__current-month,
div.react-datepicker-time__header,
div.react-datepicker-year-header {
  color: #fff;
}

div.react-datepicker__navigation {
  top: 9px;
}

div.react-datepicker__day--selected,
div.react-datepicker__day--selected:hover,
div.react-datepicker__day--in-selecting-range,
div.react-datepicker__day--in-selecting-range:hover,
div.react-datepicker__day--in-range,
div.react-datepicker__day--in-range:hover,
div.react-datepicker__month-text--selected,
div.react-datepicker__month-text--selected:hover,
div.react-datepicker__month-text--in-selecting-range,
div.react-datepicker__month-text--in-selecting-range:hover,
div.react-datepicker__month-text--in-range,
div.react-datepicker__month-text--in-range:hover,
div.react-datepicker__quarter-text--selected,
div.react-datepicker__quarter-text--selected:hover,
div.react-datepicker__quarter-text--in-selecting-range,
div.react-datepicker__quarter-text--in-selecting-range:hover,
div.react-datepicker__quarter-text--in-range,
div.react-datepicker__quarter-text--in-range:hover,
div.react-datepicker__year-text--selected,
div.react-datepicker__year-text--selected:hover,
div.react-datepicker__year-text--in-selecting-range,
div.react-datepicker__year-text--in-selecting-range:hover,
div.react-datepicker__year-text--in-range,
div.react-datepicker__year-text--in-range:hover {
  background: #333;
  border-radius: 100%;
  transition: 0s;
}

div.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #333;
}

/* Calendar style rewrite */
div .rmdp-wrapper {
  width: 100%;
}

div .rmdp-day-picker div {
  flex: 1 1 calc(100% - 10px);
  margin: 0;
}

div .rmdp-day.rmdp-today span {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

div .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  border-radius: 5px;
  background-color: #475aff;
  color: #fff;
}

div div .rmdp-day.rmdp-today span {
  background-color: transparent;
  color: inherit;
  box-shadow: 0 10px 0 -7px #475aff;
  border-radius: 0px;
}

div.rmdp-disabled span {
  color: #ccc;
}

div .rmdp-calendar {
  width: 100%;
}

div .rmdp-shadow {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

div .rmdp-range {
  background-color: #475aff;
  box-shadow: none;
  color: #fff;
}

div .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #3374f6;
  border-radius: 5px;
  box-shadow: none;
}

div .rmdp-range.start {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #475aff;
  color: #fff;
}

div .rmdp-range.end {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #475aff;
  color: #fff;
}

/* Loading */
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}

.swiper-pagination-bullet {
  background: white !important; /* 非活躍狀態的點點顏色 */
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  /* 橢圓形點點 */
  width: 16px !important;
  height: 8px !important;
  border-radius: 35% !important;
  background: #3374f6 !important; /* 活躍狀態的點點顏色 */
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  height: 0; /* 水平滾動條的高度 */
  width: 3px; /* 垂直滾動條的寬度 */
}

::-webkit-scrollbar-thumb {
  background: #3374f6; /* 滾動條的背景色 */
  border-radius: 20px; /* 滾動條的圓角 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

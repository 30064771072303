#blog-detail {
  line-height: 1.7;
  letter-spacing: auto;
  white-space: initial;
}

#blog-detail blockquote {
  padding: 15px;
  background-color: var(--chakra-colors-gray-100);
  border-radius: 10px;
  margin-bottom: 30px;
}

#blog-detail blockquote p {
  margin: 0;
}

#blog-detail p {
  font-size: 17px;
  margin-bottom: 15px;
  margin-top: 15px;
}

#blog-detail strong {
  font-size: 20px;
  margin-top: 20px;
  display: inline-block;
}

#blog-detail img {
  margin: 20px auto;
}

#blog-detail p img {
  display: inline-block;
  margin: auto;
}

#blog-detail blockquote * {
  margin: auto;
}

#blog-detail strong {
  margin: auto;
  font-size: 17px;
  color: #296195;
  font-weight: bold;
  display: inline;
}

#blog-detail .has-large-font-size > * {
  font-size: 30px;
  color: #333;
  margin-top: 20px;
  font-weight: bold;
}

#blog-detail .has-small-font-size,
#blog-detail .has-small-font-size > * {
  font-size: 15px;
}

#blog-detail .has-small-font-size > strong {
  font-weight: 16px;
  color: #333;
}

#blog-detail figure:has(table) {
  overflow-x: auto;
}

#blog-detail figure:has(table) {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  #blog-detail table {
    font-size: 13px;
    text-align: left;
  }
}

#blog-detail table {
  width: 100%;
  text-align: center;
  /* min-width: 430px; */
}

#blog-detail thead th {
  padding-top: 6px;
  padding-bottom: 6px;
}

#blog-detail thead {
  background-color: #333;
  color: #fff;
}

#blog-detail table td {
  border-width: 1px;
  padding: 5px 5px;
}

#blog-detail tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}

/* 用來在 wordpress 文章裡顯示 code block */
pre.wp-block-code {
  background-color: #263238; /* 深色背景 */
  border: 1px solid #ffccbc; /* 邊框強調，您可以根據需求調整顏色 */
  border-radius: 10px; /* 圓角設計 */
  padding: 20px;
  overflow: auto;
  font-family: "Courier New", Courier, monospace; /* 等寬字體增加可讀性 */
  color: #fff; /* 白色文字 */
  font-size: 13px; /* 可調整字體大小 */
}

figcaption.wp-element-caption {
  font-size: 12px;
  color: #000;
  opacity: .4;
  margin-top: -15px;
  text-align: center;
  margin-bottom: 25px;
}
